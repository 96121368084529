<template>
    <section class="">
        <div class="row mx-3 bg-white br-12 py-3">
            <div class="col-auto text-general f-17 f-600 d-middle">
                Calendario de pagos de comisión (Últimos 3 meses + Mes actual)
            </div>
            <div v-if="$can('boton_tesoreria_comisiones_reportar_pago')" class="col-auto ml-auto">
                <div v-if="verBotones" class="bg-general d-middle-center cr-pointer shadow px-3 br-10 text-white f-500" style="height:32px;" @click="pagarComision">
                    Pagar comisión
                </div>
            </div>
            <div class="col-12 px-0">
                <div class="row justify-center mx-0 my-3">
                    <div v-for="(mes,i) in data_mes" :key="i" class="col-auto px-1 my-2">
                        <div class="calendar-container br-12 border">
                            <div class="row mx-0 header-calendar d-middle-center">
                                {{ mes.nombre }} {{ mes.ano }}
                            </div>
                            <div class="row mx-0 sub-header">
                                <div v-for="(day, d) in days" :key="`${i}-${d}`" class="days d-middle-center" v-text="day.text" />
                            </div>
                            <div class="row mx-0 content-days">
                                <el-tooltip v-for="(number, n) in obtener_dias(mes)" :key="n" :disabled="!hayPago(mes,number.i,1)" placement="bottom" effect="light">
                                    <div slot="content" style="max-width:100px">
                                        <p>{{ hayPago(mes,number.i,2) }}</p>
                                        <p>{{ hayPago(mes,number.i,3) }}</p>
                                    </div>
                                    <div class="number-day d-middle-center" :class="hayPago(mes,number.i,1) ? 'day-paid' : ''" v-text="number.i" />
                                    <!-- {{num}} -->
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="$can('info_tesoreria_comisiones_pagos')" class="row mx-3 mt-3">
            <div class="col-12 bg-white br-t-12">
                <p class="text-general f-18 f-600 my-3 text-left">
                    Historial de pagos de comisión
                </p>
            </div>
            <div class="col-12 px-0">
                <el-table :data="tabla_paginada" header-row-class-name="f-600 text-general" stripe style="width: 100%">
                    <el-table-column label="Periodo de comisión" class-name="text-center" width="250">
                        <template slot-scope="{ row }">
                            <div class="col cr-pointer" @click="verComision(row)">
                                {{ `${formatearFecha(row.fecha_inicio)} - ${formatearFecha(row.fecha_fin)}` }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pago" label="Pago" header-align="center" align="right" width="180" />
                    <el-table-column prop="fecha_pago" label="Fecha de Pago" />
                    <el-table-column prop="creador" label="Responsable">
                        <template slot-scope="{ row }">
                            <div class="row mx-0">
                                <div class="col-4 px-0">
                                    {{ row.creador.nombre }}
                                </div>
                                <el-tooltip v-if="row.anulacion != null" effect="light" placement="bottom">
                                    <div slot="content" style="max-width:280px;">
                                        <div class="row mx-0">
                                            <div class="col-12 text-general f-600 f-17">
                                                Registro anulado
                                            </div>
                                            <div class="col-12">
                                                <p class="text-general f-17 my-1">
                                                    <b>
                                                        Fecha:
                                                    </b>
                                                    {{ formatearFecha(row.updated_at) }}
                                                </p>
                                                <p class="text-general f-17 f-600 my-1">
                                                    Justificación de anulación
                                                </p>
                                                <p>
                                                    {{ row.anulacion }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <i class="icon-cash-remove text-general2 f-22" />
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="col-12 d-middle-center my-3">
                <div v-if="total_tabla > total_tabla_paginado" class="bg-general3 text-white br-12 px-3 cr-pointer shadow" @click="agregarRegistros">
                    Ver más registros
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-pagar-comision ref="modalPagarComision" @listar="getPagosComision" />
        <modal-ver-comision ref="modalVerComision" @listar="getPagosComision" />
    </section>
</template>

<script>
import moment from 'moment'
import Comisiones from "~/services/comisiones";
export default {
    components: {
        modalPagarComision: () => import('../partials/modalPagarComision'),
        modalVerComision: () => import('../partials/modalVerComision')
    },
    props:{
        idLeechero : {
            type: Number,
            default: null
        },
        monedaLeechero : {
            type: Number,
            default: null
        },
        monedaRol : {
            type: Number,
            default: null
        },
        verBotones : {
            type: Boolean,
            default: true
        },
    },
    data(){
        return {
            days: [
                { text: 'D', id: 1},
                { text: 'L', id: 2},
                { text: 'M', id: 3},
                { text: 'M', id: 4},
                { text: 'J', id: 5},
                { text: 'V', id: 6},
                { text: 'S', id: 7},
            ],
            data_mes: [],
            calendario: [],
            total_por_pagina: 50,
            tabla: [],
            tabla_paginada: [],
            total_tabla: 0,
            total_tabla_paginado: 0,
        }
    },
    watch: {
        idLeechero( newVal,old){
            // if(old == null) return
            this.getPagosComision()
        },
        monedaRol( newVal,old){
            // if(old == null) return
            this.getPagosComision()
        }
    },
    mounted(){
        this.fillDates()
        this.getPagosComision()
    },
    methods: {
        async getPagosComision(){
            try {

                if(this.idLeechero == null) return
                let params = {
                    id_moneda: this.monedaRol,
                }
                const {data} = await Comisiones.getPagosComision(this.idLeechero, params)

                this.calendario = data.calendario
                data.tabla.map(el => {
                    el.pago = this.separadorNumero(el.pago)
                    el.fecha_pago = this.formatearFecha(el.fecha_pago)
                })
                if (!this.verBotones){
                    data.tabla = data.tabla.filter(el => el.anulacion == null)
                }
                this.tabla = data.tabla
                this.tabla_paginada = this.tabla.slice(0,this.total_por_pagina)
                this.total_tabla = this.tabla.length
                this.total_tabla_paginado = this.tabla_paginada.length
            } catch (e){
                this.error_catch(e)
            } finally{
                // this.cargandoLeecheros = false
            }
        },
        agregarRegistros(){
            if (this.total_tabla >= this.total_por_pagina){
                this.total_por_pagina += 4
                this.tabla_paginada = this.tabla.slice(0,this.total_por_pagina)
                this.total_tabla_paginado = this.tabla_paginada.length
            }
        },
        fillDates(){
            let array = []

            for (let i = 3; i >= 0; i--){
                let fecha = moment().subtract(i, 'M')

                array.push({
                    mes: fecha.format('MM'),
                    nombre: fecha.format('MMMM'),
                    ano: fecha.format('Y')
                })
            }
            this.data_mes = _.orderBy(array, ['ano'], ['asc'])
        },
        pagarComision(){
            this.$refs.modalPagarComision.toggle(this.idLeechero, this.monedaRol);
        },
        verComision(row){
            row.verBotones = this.verBotones
            this.$refs.modalVerComision.toggle(row);
        },
        obtener_dias(tmp_mes){
            var diaSemana = moment(`${tmp_mes.ano}-${tmp_mes.mes}-01`).day()
            var ultimodia = moment(`${tmp_mes.ano}-${tmp_mes.mes}`, 'YYYY-MM').daysInMonth()
            var mes = moment(`${tmp_mes.ano}-${tmp_mes.mes}`, 'YYYY-MM').format('MMMM')
            let tmp = []
            switch (parseInt(diaSemana)){
            case 6:{
                for (var i = 1; i <= 6; i++){
                    tmp.push({ i: null, popover: false })
                }
                break
            }

            case 5:{
                for (var i = 1; i <= 5; i++){
                    tmp.push({ i: null, popover: false })
                }
                break
            }

            case 4:{
                for (var i = 1; i <= 4; i++){
                    tmp.push({ i: null, popover: false })
                }
                break
            }

            case 3:{
                for (var i = 1; i <= 3; i++){
                    tmp.push({ i: null, popover: false })
                }
                break
            }

            case 2:{
                for (var i = 1; i <= 2; i++){
                    tmp.push({ i: null, popover: false })
                }
                break
            }

            case 1:{
                for (var i = 1; i <= 1; i++){
                    tmp.push({ i: null, popover: false })
                }
                break
            }
            /* case 0:{
                for (var i = 0; i <= 0; i++){
                    tmp.push({ i: null, popover: false })
                }
            }
                break*/

            default:
            }

            for (var i = 1; i <= ultimodia; i++){
                tmp.push({ i, popover: false })
            }

            return tmp
        },
        hayPago(mes, dia, tipo){
            let respuesta
            let fecha = moment(`${mes.ano}-${mes.mes}-${dia < 10 ? `0${dia}` : dia}`)
            let pago = this.calendario.find(el => el.fecha_pago == fecha.format('Y-MM-DD'))

            switch (tipo){
            case 1:
                respuesta = !!pago

                break;
            case 3:
                respuesta = pago ? this.separadorNumero(pago.total) : 0

                break;
            case 2:
                respuesta = fecha.format('D MMM Y')

                break;

            default:
                break;
            }

            return respuesta
        }

    }
}
</script>

<style lang="scss" scoped>
.calendar-container{
    width: 283px;
    height: 313px;
    .header-calendar{
        background-color: var(--text-general);
        border-top-left-radius: 12px;
        height: 35px;
        border-top-right-radius: 12px;
        font-size: 15;
        color: #ffffff;
    }
    .sub-header{
        height: 35px;
        background-color: #EBEBEB;
        .days{
            width: 40px;
            height: 100%;
        }
    }
    .content-days{
        height: 40px;
        .number-day{
            width: 40px;
            height: 100%;
        }
    }
}
.day-paid{
    background-color: var(--text-general);
    border-radius: 50%;
    color: #ffffff !important;
}
</style>
